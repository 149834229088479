export default {
  meta_title: 'Setup profile — PennyLane',
  meta_description:
    'A Python library for quantum machine learning, automatic differentiation, and optimization of hybrid quantum-classical computations. Use multiple hardware devices, alongside TensorFlow or PyTorch, in a single computation.',
  meta_image: 'https://assets.cloud.pennylane.ai/pennylane_website/generic/thumbnail.png',
  cardContent: {
    title: 'Tell us about yourself',
    description: `Entering your first name and last name will make it visible on your public profile along with your username.`,
    radioLabel: 'Do you use PennyLane?',
    submitButtonLabel: 'Complete setup',
    backButtonLabel: 'Cancel',
    usernameInvalidCharactersError:
      'Username must be between 3-20 characters, and can only contain alphanumeric characters and underscores.',
    usernameUnavailableError: `This username is unavailable`,
    usernameAvailableText: `This username is available`,
  },
}
