import { useEffect, useState } from 'react'
import { Input } from 'component-library'
import { gql, useQuery } from '@apollo/client'
import { get } from 'lodash'
import setupProfileContent from '../../content/auth/setup_profile'

export const CHECK_IF_USERNAME_IS_AVAILABLE = gql(`
  query handleIsAvailable($handle: String!) {
    handleIsAvailable(handle: $handle)
  }
`)

const { cardContent } = setupProfileContent

// Allow only alphanumeric and underscore characters
const USERNAME_REGEX = /^[a-zA-Z0-9_]+$/

export const isUserNameValid = (username: string) => {
  return new RegExp(USERNAME_REGEX).test(username)
}

const UserHandle = ({
  handle,
  assignedHandle,
  onChange,
  isUsernameAvailable = () => null,
}: {
  handle: string
  assignedHandle?: string
  onChange: (value: string | number) => void
  isUsernameAvailable?: (available: boolean) => void
}) => {
  const [usernameAvailable, setUsernameAvailable] = useState<boolean>(false)
  const [usernameError, setUsernameError] = useState<string>('')
  const {
    data: userNameCheckResponse,
    error: usernameCheckError,
    loading: checkingUsername,
  } = useQuery(CHECK_IF_USERNAME_IS_AVAILABLE, {
    variables: { handle: handle },
    skip:
      !handle ||
      handle.length < 3 ||
      assignedHandle === handle ||
      !isUserNameValid(handle),
  })

  useEffect(() => {
    if (userNameCheckResponse) {
      const usernameAvailable =
        get(userNameCheckResponse, 'handleIsAvailable') === 'AVAILABLE'
      if (!usernameAvailable) {
        setUsernameError(cardContent.usernameUnavailableError)
      } else {
        setUsernameError('')
      }

      updateUsernameAvailability(usernameAvailable)
    }
    if (usernameCheckError) {
      setUsernameError('Something went wrong, please try again later.')
    }
  }, [userNameCheckResponse, usernameCheckError])

  const updateUsernameAvailability = (isAvailable: boolean) => {
    setUsernameAvailable(isAvailable)
    isUsernameAvailable(isAvailable)
  }

  useEffect(() => {
    const usernameValid = isUserNameValid(handle)
    if (!usernameValid) {
      setUsernameError(cardContent.usernameInvalidCharactersError)
      updateUsernameAvailability(false)
    } else setUsernameError('')
  }, [handle])

  return (
    <Input
      fieldLabel="Username"
      fieldLabelHidden
      fieldName="Username"
      formName="SetupProfileForm"
      placeholder="Username"
      required
      testId="username-input"
      type="text"
      minLength={3}
      maxLength={20}
      className={
        handle?.length >= 3 && usernameAvailable ? 'success-info-text' : ''
      }
      updateValue={(value: string | number) => onChange(value ? value : '')}
      value={handle}
      leftIcon="bx bx-at"
      iconPosition="twoIcons"
      rightIcon={
        handle?.length >= 3 && assignedHandle !== handle && !checkingUsername
          ? usernameAvailable
            ? 'text-primary-green-3 bx bxs-check-circle'
            : 'text-error-1 bx bxs-error-circle'
          : ''
      }
      errorText={
        handle?.length >= 3 &&
        !usernameAvailable &&
        assignedHandle !== handle &&
        !checkingUsername
          ? usernameError
          : ''
      }
      infoText={
        handle?.length >= 3 &&
        usernameAvailable &&
        assignedHandle !== handle &&
        !checkingUsername
          ? cardContent.usernameAvailableText
          : ''
      }
    />
  )
}

export default UserHandle
