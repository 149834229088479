import { useEffect } from 'react'
import Seo from '../../components/seo'
import setupProfileContent from '../../content/auth/setup_profile'
import SetupProfile from '../../components/SetupProfile/SetupProfile'
import { useQueryParamString } from 'react-use-query-param-string'
import { navigate } from 'gatsby'
import LoaderSpinner from '../../components/LoaderSpinner/LoaderSpinner'
import '../../scss/pages/auth.scss'

const { meta_title, meta_description, meta_image } = setupProfileContent

const SetupProfilePage = () => {
  const [sessionToken, , tokenInitialized] = useQueryParamString(
    'session_token',
    ''
  )
  const [state, , stateInitialized] = useQueryParamString('state', '')

  useEffect(() => {
    if ((tokenInitialized && !sessionToken) || (stateInitialized && !state))
      navigate('/')
  }, [tokenInitialized, sessionToken])

  return (
    <>
      {!tokenInitialized || !sessionToken || !stateInitialized || !state ? (
        <div className="w-full h-screen flex justify-center items-center">
          <LoaderSpinner />
        </div>
      ) : (
        <SetupProfile sessionToken={sessionToken} state={state} />
      )}
    </>
  )
}

export default SetupProfilePage

export const Head = () => {
  return (
    <Seo title={meta_title} description={meta_description} image={meta_image} />
  )
}
