import { SyntheticEvent, useState } from 'react'
import AuthFooter from '../AuthFooter/AuthFooter'
import setupProfileContent from '../../content/auth/setup_profile'
import MarkdownText from '../MarkdownText/MarkdownText'
import { Button, Input, RadioGroup } from 'component-library'
import ProfileSetupDropdown from '../ProfileSetupDropdown/ProfileSetupDropdown'
import { withPrefix } from 'gatsby'
import { useAuth0 } from '@auth0/auth0-react'
import { getCurrentEnvConfig } from '../../../config'
import { get } from 'lodash'
import UserHandle from '../UserHandle/UserHandle'
import { gql, useMutation } from '@apollo/client'
import './SetupProfile.scss'

export const CREATE_USER_PROFILE = gql(`
  mutation profileCreate($sessionToken: String!, $handle: String!, $personas: [PersonaType!]!, $usesPennylane: Boolean!, $firstName: String, $lastName: String, $personaOther: String) {
    profileCreate(
      model: {handle: $handle, personas: $personas, survey: {usesPennylane: $usesPennylane}, firstName: $firstName, lastName: $lastName, personaOther: $personaOther}
      sessionToken: $sessionToken
    ){
      userError {
        field
        message
      }
    }
  }
`)

const config = getCurrentEnvConfig()
const pennyLaneBlueBackground =
  'https://assets.cloud.pennylane.ai/pennylane_website/backgrounds/pennylane_background_blue.png'

interface IProfileData {
  firstName: string | null
  lastName: string | null
  handle: string
  userPersona: string[]
  usesPennyLane: 'Not Selected' | 'Yes' | 'No'
}

const { cardContent } = setupProfileContent

const SetupProfile = ({
  sessionToken,
  state,
}: {
  sessionToken: string
  state: string
}) => {
  const { logout } = useAuth0()
  const [profileData, setProfileData] = useState<IProfileData>({
    firstName: null,
    lastName: null,
    handle: '',
    userPersona: [],
    usesPennyLane: 'Not Selected',
  })
  const [otherPersonaValue, setOtherPersonaValue] = useState<string | null>(
    null
  )

  const [profileCreateError, setProfileCreateError] = useState<string>('')
  const [usesPennyLaneError, setUsesPennyLaneError] = useState<string>('')
  const [userPersonaError, setUserPersonaError] = useState<string>('')
  const [usernameAvailable, setUsernameAvailable] = useState<boolean>(true)

  const [profileCreateMutation] = useMutation(CREATE_USER_PROFILE)

  const onUserPersonaChange = (userPersona: string[]) => {
    if (!userPersona.includes('OTHER')) {
      setOtherPersonaValue(null)
    }
    setProfileData({ ...profileData, userPersona })
  }

  const onRadioButtonChange = (value: string) => {
    const usesPennyLane = value as 'Not Selected' | 'Yes' | 'No'
    setProfileData({
      ...profileData,
      usesPennyLane,
    })
  }

  const submitProfileData = async (e: SyntheticEvent) => {
    e.preventDefault()
    let hasError = false

    if (profileData.usesPennyLane === 'Not Selected') {
      setUsesPennyLaneError('This field is required')
      hasError = true
    }
    if (!profileData.userPersona.length) {
      setUserPersonaError('This field is required')
      hasError = true
    }
    if (!usernameAvailable) {
      setUserPersonaError('')
      hasError = true
    }

    if (!hasError) {
      setUsesPennyLaneError('')
      const submissionData = {
        ...profileData,
        userPersona: [...profileData.userPersona],
      }
      try {
        const profileCreateResponse = await profileCreateMutation({
          variables: {
            sessionToken: sessionToken,
            handle: submissionData.handle,
            personas: submissionData.userPersona,
            usesPennylane:
              submissionData.usesPennyLane === 'Yes' ? true : false,
            firstName: submissionData.firstName,
            lastName: submissionData.lastName,
            personaOther: otherPersonaValue,
          },
        })
        const userErrorMessage = get(
          profileCreateResponse,
          'data.profileCreate.userError.message'
        )
        const genericErrorMessages = get(profileCreateResponse, 'errors')
        if (userErrorMessage)
          setProfileCreateError(userErrorMessage ? userErrorMessage : '')
        else if (genericErrorMessages && genericErrorMessages.length)
          setProfileCreateError(genericErrorMessages[0].message)
        else
          typeof window !== 'undefined'
            ? window.open(
                `https://${config.auth0.domain}/continue?state=${state}`,
                '_self'
              )
            : null
      } catch (err) {
        console.error(err)
        setProfileCreateError('Something went wrong, please try again later.')
      }
    }
  }

  return (
    <div
      className="AuthLayout"
      style={{ backgroundImage: `url(${withPrefix(pennyLaneBlueBackground)})` }}
    >
      <div className="AuthCard">
        <h2 className="mb-6">{cardContent.title}</h2>
        <MarkdownText
          text={cardContent.description}
          className="AuthCard__description"
        />
        <form className="SetupProfileForm" onSubmit={submitProfileData}>
          <div className="flex gap-4">
            <Input
              fieldLabel="First name"
              fieldLabelHidden
              fieldName="First name"
              maxLength={25}
              formName="SetupProfileForm"
              placeholder="First name"
              testId="first-name-input"
              type="text"
              updateValue={(value: string | number) =>
                setProfileData({
                  ...profileData,
                  firstName: value ? `${value}` : null,
                })
              }
              value={profileData.firstName ? profileData.firstName : ''}
            />
            <Input
              fieldLabel="Last name"
              fieldLabelHidden
              fieldName="Last name"
              maxLength={25}
              formName="SetupProfileForm"
              placeholder="Last name"
              testId="last-name-input"
              type="text"
              updateValue={(value: string | number) =>
                setProfileData({
                  ...profileData,
                  lastName: value ? `${value}` : null,
                })
              }
              value={profileData.lastName ? profileData.lastName : ''}
            />
          </div>
          <UserHandle
            handle={profileData.handle}
            onChange={(value) =>
              setProfileData({ ...profileData, handle: `${value}` })
            }
            isUsernameAvailable={(available) => setUsernameAvailable(available)}
          />
          <ProfileSetupDropdown
            onChange={(userPersona) => onUserPersonaChange(userPersona)}
            preSelectedValues={profileData.userPersona}
          />
          {!profileData.userPersona.length && userPersonaError && (
            <p className="text-error-1 mt-2 text-sm">{userPersonaError}</p>
          )}
          {profileData.userPersona.includes('OTHER') && (
            <Input
              fieldLabel="Other Persona"
              fieldLabelHidden
              fieldName="Other"
              formName="SetupProfileForm"
              placeholder="Other — Please specify..."
              required
              testId="other-persona-input"
              type="text"
              updateValue={(value: string | number) =>
                setOtherPersonaValue(`${value}`)
              }
              value={otherPersonaValue ? otherPersonaValue : ''}
            />
          )}
          <p className="mt-4 mb-2">{cardContent.radioLabel}</p>
          <RadioGroup
            className="SetupProfile__radio-group"
            onChange={(value) => onRadioButtonChange(`${value}`)}
            radioButtons={[
              {
                id: 'uses-pennyLane-yes',
                label: 'Yes',
                name: 'uses-pennyLane-yes',
                value: 'Yes',
              },
              {
                id: 'uses-pennyLane-no',
                label: 'No',
                name: 'uses-pennyLane-no',
                value: 'No',
              },
            ]}
            selectedValue={profileData.usesPennyLane}
          />
          {usesPennyLaneError &&
            profileData.usesPennyLane === 'Not Selected' && (
              <p className="text-error-1 text-sm -mt-1">{usesPennyLaneError}</p>
            )}
          {profileCreateError && (
            <p className="text-error-1 text-sm my-2">
              <i className="bx bxs-error-circle"></i> {profileCreateError}
            </p>
          )}
          <Button
            label={cardContent.submitButtonLabel}
            onClick={() => null}
            type="primary"
            className="w-full mt-4"
            buttonType="submit"
            testId="submit-profile-button"
          />
        </form>
        <Button
          label={cardContent.backButtonLabel}
          type="basic"
          onClick={() => logout({ logoutParams: { returnTo: config.siteURL } })}
          className="w-full mt-6 text-center font-normal text-[#757575] my-6 underline"
        />
      </div>
      <AuthFooter />
    </div>
  )
}

export default SetupProfile
