import { useState, useEffect, useRef } from 'react'
import { Select } from 'component-library'
import { useCloseOnEscape } from 'shared-utilities'
import { get } from 'lodash'
import { gql, useQuery } from '@apollo/client'
import { useOnClickOutside } from 'usehooks-ts'
import './ProfileSetupDropdown.scss'

export const GET_USER_PERSONA_TYPES = gql(`
  query getPersonaTypes {
    personaTypes
  }
`)

const ProfileSetupDropdown = ({
  onChange,
  preSelectedValues = [],
}: {
  onChange: (selectedUserPersonas: string[]) => void
  preSelectedValues?: string[]
}) => {
  const [openDropdown, setOpenDropdown] = useState(false)
  const [personaError, setPersonaError] = useState('')
  const [selectedOptions, setSelectedOptions] = useState<
    { label: string; value: string }[]
  >([])
  const [roleOptions, setRoleOptions] = useState<
    { label: string; value: string }[]
  >([])

  const { data, error } = useQuery(GET_USER_PERSONA_TYPES)

  useEffect(() => {
    if (preSelectedValues && preSelectedValues.length) {
      const formattedPersonas = preSelectedValues.map((persona: string) => {
        return {
          label: persona[0] + persona.slice(1).toLowerCase(),
          value: persona,
        }
      })
      setSelectedOptions(formattedPersonas)
    } else {
      setSelectedOptions([])
    }
  }, [preSelectedValues])

  useEffect(() => {
    // Get persona type values from query
    let personaTypes: string[] = [...get(data, 'personaTypes', [])]

    if (personaTypes && personaTypes.length) {
      setPersonaError('')
      // Move 'OTHER' to the end of personaTypes Array
      const otherIndex = personaTypes.indexOf('OTHER')
      if (otherIndex != -1) {
        personaTypes.splice(otherIndex, 1)
        personaTypes = [...personaTypes, 'OTHER']
      }
      // Create dropdown values as accepted by Select component
      const formattedPersonas = personaTypes.map((persona: string) => {
        return {
          label: persona[0] + persona.slice(1).toLowerCase(),
          value: persona,
        }
      })
      // Set Dropdown options
      setRoleOptions(formattedPersonas)
    }
    if (error) {
      setPersonaError('Could not fetch user personas, please try again later.')
    }
  }, [data, error])

  const ref = useRef(null)
  useOnClickOutside(ref, () => setOpenDropdown(false))
  useCloseOnEscape(openDropdown, setOpenDropdown)

  const handleCheckUncheckUserPersona = (userPersona: string[]) => {
    onChange(userPersona)
  }

  return (
    <div className="ProfileSetupDropdown" ref={ref}>
      <Select
        fieldLabel="I am a/an..."
        placeholder="I am a/an..."
        fieldName="role"
        formName="SetupProfileForm"
        isMulti={true}
        name="User Persona"
        options={roleOptions}
        className="basic-multi-select"
        classNamePrefix="select"
        maxMenuHeight={200}
        closeMenuOnSelect={false}
        onChange={(selectedValues) => {
          const localValues = selectedValues as {
            label: string
            value: string
          }[]
          handleCheckUncheckUserPersona(localValues.map((value) => value.value))
        }}
        value={selectedOptions}
      />
      {personaError && (
        <p className="text-error-1 my-2">
          <i className="bx bxs-error-circle"></i> {personaError}
        </p>
      )}
    </div>
  )
}

export default ProfileSetupDropdown
